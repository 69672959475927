<template>
  <b-overlay :show="updating" rounded="sm">
    <div class="container">
      <div class="row mt-5">
        <div class="col">
          <h2 class="display-4 text-center">
            {{ $t("menu.manage_services") }}
          </h2>
          <ManageServicesForm
            ref="currentForm"
            :form-data="form"
            :on-submit="onSubmit"
          />
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import i18n from "@/i18n";
import ManageServicesForm from "@/components/forms/profile/ManageServicesForm";
import { mapActions, mapGetters } from "vuex";
import router from "../router";

export default {
  name: "Profile",
  components: { ManageServicesForm },
  metaInfo: {
    titleTemplate: "%s" + i18n.t("menu.manage_services"),
  },
  data() {
    return {
      form: this.getDefaultFormData(),
    };
  },
  computed: {
    ...mapGetters({
      updating: "account/sendingContactForm",
    }),
  },
  methods: {
    ...mapActions({
      updateServiceParameters: "account/updateServiceParameters",
    }),
    getDefaultFormData() {
      return this.$loggedUser();
    },
    onSubmit(form) {
      console.log("onSubmit di ManageService.vue", form);
      //this.updateServiceParameters(form);
    },
  },
  mounted() {
    if (!this.$loggedUser().is_affiliato) {
      router.push({
        name: "home",
      });
    }
  },
};
</script>

<template>
  <b-form @submit.stop.prevent="onChildSubmit">
    <!-- Babysitter -->
    <div v-for="item in dataMargini" :key="item.id"> <!-- Cerca nella parte JS datamargini, dove prende e cosa prende-->
      <div v-if="item.servizio.affiliato_id == item.affiliato.affiliato_id">  
        <div v-if="item.servizio && item.servizio.name">
          <hr :data-content="item.servizio.name" class="hr-text" />
        </div>
        <b-form-row>
          <b-col lg="6">
            <b-form-group
              id="prezzo_badante_babysitter-input-group"
              :label="$t('management.caretaker_cost')"
              label-for="prezzo_badante_babysitter"
            >
              <b-form-input
                id="prezzo_badante_babysitter"
                name="prezzo_badante_babysitter"
                type="number"
                step=".01"
                aria-describedby="prezzo_badante_babysitter-live-feedback"
                :value="getPrezzoOrario(item)"
                @change="onChangePrezzo(item, $event)"
              >
              </b-form-input>
              <!-- <b-form-invalid-feedback
                id="prezzo_badante_babysitter-live-feedback"
              >
                <div v-if="!$v.form.prezzo_badante_babysitter.required">
                  {{ $t("validation.required") }}
                </div>
              </b-form-invalid-feedback> -->
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              id="margine_babysitter-input-group"
              :label="$t('management.margin')"
              label-for="margine_babysitter"
            >
              <b-form-input
                id="margine_babysitter"
                name="margine_babysitter"
                type="number"
                step=".01"
                aria-describedby="margine_babysitter-live-feedback"
                :value="item.margine"
                @change="onChangeMargine(item, $event)"
              >
              </b-form-input>
              <!-- <b-form-invalid-feedback id="margine_babysitter-live-feedback">
                <div v-if="!$v.form.margine_babysitter.required">
                  {{ $t("validation.required") }}
                </div>
              </b-form-invalid-feedback> -->
            </b-form-group>
          </b-col>
        </b-form-row>
      </div>  
    </div>
    <div class="mb-2 text-right">
      <!-- Actions -->
      <b-button type="submit" variant="success">{{ $t("update") }} </b-button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ManageServicesForm",
  mixins: [validationMixin],
  props: {
    formData: {
      type: Object,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: this.getDefaultFormData(), // 
      dataMargini: [],
    };
  },
  computed: {
    ...mapGetters({
      margini: "margini/margini",
    }),
  },
  validations: {
    form: {
    },
  },
  methods: {
    getDefaultFormData() {
      const id = this.formData && this.formData ? this.formData.id : null;
      console.log("The id is", id);
      return {
        id: id,
      }
    },
    getPrezzoOrario(item) {
    return item && item.servizio ? item.servizio.prezzo_orario : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onChildSubmit() {
      this.$v.form.$touch();
      
      // Filter items based on the condition for showing them on the frontend
      const itemsToShow = this.dataMargini.filter(item => {
        return item.servizio && item.servizio.affiliato_id === item.affiliato.affiliato_id;
      });

      // Prepare payload with only the itemsToShow
      const payload = itemsToShow.map(item => ({
        id: item.id,
        margine: item.margine,
        servizio: item.servizio.id,
        affiliato: item.affiliato_id,
        prezzo: item.servizio.prezzo_orario
      }));

      // Submit only the relevant data to the backend
      await this.updateMarginiRequest({
        dati: payload,
      });

      // Call onSubmit with the form data
      this.onSubmit(this.form);
    },
    // async onChildSubmit() {
    //   console.log("margini")
    //   console.log(this.dataMargini)

    //   this.$v.form.$touch();
    //   console.log("form")
    //   console.log(this.form)
    //   var payload = []

    //   this.dataMargini.forEach((item) => {
    //       payload.push({
    //         id: item.id,
    //         margine: item.margine,
    //         servizio: item.servizio.id,
    //         affiliato: item.affiliato_id,
    //         prezzo: item.servizio.prezzo_orario
    //       });
    //   });

    //   var dati = payload;
    //   /* if (this.$v.form.$anyError) {
    //     return;
    //   }*/
    //   await this.updateMarginiRequest({
    //     dati,
    //   });

    //   this.onSubmit(this.form);
    // },
    onChangeMargine(obj, inputValue) {
      const itemToUpdate = this.dataMargini.find((item) => item.id === obj.id);
      if (itemToUpdate) {
        itemToUpdate.margine = inputValue;
      }
      console.log(this.dataMargini);
    },
    onChangePrezzo(obj, inputValue) {
      const itemToUpdate = this.dataMargini.find((item) => item.id === obj.id);
      if (itemToUpdate) {
        itemToUpdate.servizio.prezzo_orario = inputValue;
      }
      console.log(this.dataMargini);
    },

    ...mapActions({
      getMargini: "margini/getMargini",
      updateMarginiRequest: "margini/updateMarginiServizi",
    }),
    // Mappa l'azione 'getMargini' dal modulo 'margini'
  },
  async mounted() {
  await this.getMargini();
  this.dataMargini = this.margini;
  console.log("QUESTO E' DATA MARGINI",this.dataMargini); // Add this line to inspect dataMargini
  },
   
  created() {
    // Chiamare fetchData all'avvio del componente
  },
};
</script>

<style scoped>
hr.hr-text {
  position: relative;
  border: none;
  height: 1px;
  background: #999;
}

hr.hr-text::before {
  content: attr(data-content);
  display: inline-block;
  background: #fff;
  font-weight: bold;
  font-size: 0.85rem;
  color: #999;
  border-radius: 30rem;
  padding: 0.2rem 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

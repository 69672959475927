var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-overlay", { attrs: { show: _vm.updating, rounded: "sm" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row mt-5" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("h2", { staticClass: "display-4 text-center" }, [
              _vm._v(" " + _vm._s(_vm.$t("menu.manage_services")) + " ")
            ]),
            _c("ManageServicesForm", {
              ref: "currentForm",
              attrs: { "form-data": _vm.form, "on-submit": _vm.onSubmit }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      on: {
        submit: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.onChildSubmit($event)
        }
      }
    },
    [
      _vm._l(_vm.dataMargini, function(item) {
        return _c("div", { key: item.id }, [
          item.servizio.affiliato_id == item.affiliato.affiliato_id
            ? _c(
                "div",
                [
                  item.servizio && item.servizio.name
                    ? _c("div", [
                        _c("hr", {
                          staticClass: "hr-text",
                          attrs: { "data-content": item.servizio.name }
                        })
                      ])
                    : _vm._e(),
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "prezzo_badante_babysitter-input-group",
                                label: _vm.$t("management.caretaker_cost"),
                                "label-for": "prezzo_badante_babysitter"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "prezzo_badante_babysitter",
                                  name: "prezzo_badante_babysitter",
                                  type: "number",
                                  step: ".01",
                                  "aria-describedby":
                                    "prezzo_badante_babysitter-live-feedback",
                                  value: _vm.getPrezzoOrario(item)
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangePrezzo(item, $event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "margine_babysitter-input-group",
                                label: _vm.$t("management.margin"),
                                "label-for": "margine_babysitter"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "margine_babysitter",
                                  name: "margine_babysitter",
                                  type: "number",
                                  step: ".01",
                                  "aria-describedby":
                                    "margine_babysitter-live-feedback",
                                  value: item.margine
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeMargine(item, $event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ])
      }),
      _c(
        "div",
        { staticClass: "mb-2 text-right" },
        [
          _c("b-button", { attrs: { type: "submit", variant: "success" } }, [
            _vm._v(_vm._s(_vm.$t("update")) + " ")
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }